.inline-info {
  font-size: 14px;
  display: inline-block;

  &__label {
    margin-right: 5px;
    color: $greyMain;
  }

  &__data {
    color: $alwaysBlack;
  }
}
.select-banner {
  padding: 10px;
  margin: 2px 5px;
  height: 100%;
  cursor: pointer;
  display: inline-block;
  border: 1px solid $stroke;
  vertical-align: top;

  &__selected {
    box-shadow: $promoCardActiveBoxShadow;
    border: 1px solid $radioBtnActive;
  }

  &:hover {
    box-shadow: $promoCardBoxShadow;
  }

  &__inner {
    overflow: hidden;

    * {
      width: 100%;
      height: 100%;
    }
  }
}

.select-banner-wrapper {
  width: fit-content;
  margin: auto;
  display: inline-block;
}

.item-preview-wrapper {
  position: absolute;
  font-size: 28px;
  background-color: rgba(255, 255, 255, 0.8);
  width: 50px;
}



@include large-mobile {
  .brands__wrap--more{
    .slider.slider__brands {
      max-width: 470px;
    }
  }
}

@include tablet {
  .brands__wrap--more{
    position: relative;
    width: 100%;
    padding: 52px 0 0;

    .header {
      margin-bottom: 100px;
    }

    .slider.slider__brands {
      max-width: 810px;
    }
  }
}

@include desktop {
  .brands__wrap--more{
    margin: 0 auto;

    .header {
      margin-bottom: 58px;
    }

    .btn__hollow.back-btn {
      z-index: 1;
      position: absolute;
      display: block;
      right: 150px;
      top: 180px;
      border: 1px solid $opaqueGray2;

      span {
        color: $defaultWhite;
      }
    }

    .slider.slider__brands {
      max-width: 1140px;
      margin-bottom: 40px;

      .slick-arrow {
        top: unset;
        bottom: 15px;
      }

      .brands__item {
        display: inline-flex !important;
        margin-bottom: 60px;
        flex-wrap: wrap;

        .item__graphics-cont {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-height: 615px;
          order: 2;

          .logo-cont {
            order: -1;
          }
        }

        h2 {
          margin: 0 0 50px;
          font-size: 56px;
          line-height: 65px;
          width: 100%;
        }

        .expandable-paragraph {
          width: 45%;
          margin: 0 50px 30px 0;
        }

        .proj-info__cont {
          width: 100%;
          order: 3;

          h3 {
            margin: 0 0 30px;
          }

          .proj-info__section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 30px 50px 25px 30px;

            & > div, & > div:first-child, & > div:last-child {
              padding: 0;
            }
          }
        }

        .expand-text__btn {
          display: none;
        }

        .expandable-paragraph.hide {
          .hide__dots {
            display: none;
          }

          .hide__more {
            display: inline;
          }
        }

        .next-prev {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: 0 105px;
          order: 4;

          .next {
            text-align: right;
          }

          h3 {
            margin: 0;
          }
        }
      }
    }

    .footer {
      background: none;
    }
  }
}

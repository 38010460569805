.modal-settings-confirm {

  .text-center {
    padding-top: 10px;
  }

  &__title {
    margin: 0;
    font-size: 18px;
    margin-bottom: 10px;
  }
  ul {
    padding: 0;
    margin: 0;
    li{
      +li {
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px dashed lightgray;
      }

      display: flex;
      align-items: center;
      justify-content: flex-start;

      >* {
        &:first-child {
          font-size: 12px;
          color: gray;
          flex: none;
          width: 190px;
          margin-right: 10px;
          line-height: 1.2;
          font-style: italic;
        }
        &:last-child {
          font-weight: bold;
          font-size: 14px;
        }
      }
    }
  }
}
.order-card {
  color: $defaultActiveMain;
  background: $defaultActiveMainTransparent;
  text-align: justify;
  .ant-card-head-title {
    color: $defaultActiveMain;
  }
  .ant-card-body {
    padding: 0 24px 24px;
  }
}
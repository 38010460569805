.chat-info-label {
  padding: 5px 10px;
  border-radius: 8px;
  background-color: $defaultActiveMain;
  color: #fff;
  font-size: 12px;

  &__value {
    font-weight: bold;


  }

  a.chat-info-label__value {
    color: #fff;
    text-decoration: underline;
    
    &:hover {
      text-decoration: none;
    }
  }
}
.archive-item {
  height: 165px;

  &__controls {
    position: absolute;
    right: 0;
    width: 60px;
    top: 0;
    text-align: center;
    border-left: 1px solid $stroke;
    &__item {
      height: 55px;
      line-height: 55px;
      border-bottom: 1px solid $stroke;
      cursor: pointer;
      &:last-child {
        border: none;
      }
      &:hover {
        svg {
          transform: scale(1.1);
          path {
            fill: $tertiaryActiveColor;
          }
        }
      }

      &__link {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__info {
    margin: 10px 0;

    &__link {
      padding: 5px 20px;
      border-radius: 20px;
      background: $tertiaryMain;
      width: fit-content;
      color: $secondaryMain;
      margin-top: 15px;
      max-height: 80px;
      overflow: auto;
    }

    &__source {
      display: flex;
      align-items: center;
      height: 160px;

      * {
        max-width: 210px;
        max-height: 160px;
      }
    }
  }

  .ant-card-body {
    width: calc(100% - 60px);
    padding: 0 15px;
  }
}
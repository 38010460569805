.global-notification {
  border-radius: 18px;
  padding: 20px 30px;
  background: #27ae60;
  color: #fff;
  text-shadow: 0 0 25px rgba(0,0,0, .3);
  text-align: center;
  font-size: 16px;
  margin-bottom: 30px;

  &__text {
    max-width: 860px;
    margin: 0 auto;
  }
  .ant-typography {
    color: #fff;
    font-size: 21px;
  }

  &--success {
    background: rgba(#27ae60, .75);

  }
  &--warning {
    background: rgba(#F07D2C, .75);
  }
  &--error {
    background: rgba(#e42320, .75);
  }
}
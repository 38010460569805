$label: #cce0ff;
$label_selected: white;
$border: #cce0ff;
$border_selected: $secondaryMain;

.checkbox-select {
  &-dropdown {
    .ant-popover-arrow {
      border-right-color: $dart_blue !important;
      border-bottom-color: $dart_blue !important;
    }
    .ant-popover-inner {
      background: $dart_blue;
      display: flex;
      flex-direction: column;
      background: $dart_blue;
      border-radius: 8px;
      padding: 10px 5px;
      min-width: 200px;

      &-content {
        padding: 0;
      }
    }
  }
  &-option {
    padding: 6px 10px;

    &-label {
      margin-left: 10px;
      color: $label;
    }
  }
}

.user-list {
  .user-status-filter {
    margin-bottom: 20px;

    @include media("<768px") {
      flex-wrap: wrap;

      >* {
        margin-bottom: 10px;

        &:first-child {
          width: 100%;
        }
      }
    }

    .ant-btn {
      span {
        position: relative;

        &.user-not-activated-amount {
          position: absolute;
          right: -10px;
          top: -10px;
          background: $error_color;
          color: #fff;
          border-radius: 50%;
          width: 20px;
          line-height: 20px;
          font-size: 11px;
        }
      }
    }
  }
  &__title {
    margin-bottom: 0;
    font-size: 18px;
  }

}


.user-profile-preview {
  position: relative;
  width: 100%;
  &__content {
    padding:  0 24px;
    display: flex;
    align-items: center;

    &--collapsed {
      padding: 0;
    }

    .ant-card-body {
      display: flex;
      align-items: center;
    }
  }
  &__drop {
    position: absolute;
    left: calc(100% + 10px);
    top: 50%;
    transform: translateY(-50%) translateX(10px);
    padding: 12px 24px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 0 10px -5px $alwaysBlack;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    filter: alpha(opacity=0);
    transition: all .3s ease;

    &--visible {
      transform: translateY(-50%) translateX(0);
      opacity: 1;
      visibility: visible;
      filter: alpha(opacity=100);
    }
  }
  &__info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    font-weight: bold;
    line-height: 1;

  }
  &__info-text {
    margin: 0;
    margin-right: auto;
    font-size: 14px;
    text-transform: capitalize;
    width: 100%;
    color: $alwaysBlack;
    &:hover {
      color: $primaryMain;
    }
  }
  &__info-id {
    font-size: 10px;
    font-weight: bold;
  }
  &__controls {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    margin-top: 5px;
    list-style: none;

    li{
      +li {
        margin-left: 10px;
      }
    }
  }
  &__icon {
    background: url("../../../../assets/images/userIcon.svg") no-repeat center / 100%  100%;
    position: relative;
    width: 45px;
    height: 45px;
    display: block;
    border: none;
    cursor: pointer;
    margin: 0 auto;

  }

  &__mails {
    font-size: 18px;
    width: 45px;
    height: 45px;
    color: $greyMain;
    background-color: #fff;
    box-shadow: 0 0 15px -10px $dart_blue;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    position: relative;
    flex: none;
    margin-right: 10px;

    &--active {
      box-shadow: 0 0 15px -2px $primaryMain;
      color: $primaryMain;
      border-color: $primaryMain;
      animation: bounceShadow 8s infinite linear alternate both;

      &:hover {
        color: $primaryMain;
        .anticon {
          transform: scale(1.15);
        }
      }
      .anticon {
        transition: transform .3s ease;
      }
    }
  }

  &__mails-amount {
    position: absolute;
    right: -8px;
    top: -8px;
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $error_color;
    color: #fff;
    font-size: 10px;
    border-radius: 50%;
    font-weight: bold;
  }
}

.logout-btn {
  border: none;
  cursor: pointer;
  padding: 0;
  background: none;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  margin-top: 5px;
  color: $greyMain;
  &:hover {
    color: $primaryMain;
  }
  .anticon {
    margin-left: 5px;
  }
}

@keyframes bounceShadow {
  0%,30%,50%, 70%, 90%{
    box-shadow: 0 0 15px -2px $primaryMain;
  }
  20%, 40%, 60%, 80%, 100% {
    box-shadow: 0 0 0 0 $primaryMain;
  }
}
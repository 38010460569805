.admin-create-new-msg {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 15px 0 ;

  .ant-btn {
    &.ant-btn-lg{
      font-size: 16px;

      @include media('<568px') {
        font-size: 14px;
        height: auto;
        padding: 10px 15px;
      }
    }
  }
}

.chats-filter-dropdown {
  width: 400px;

  @include media('<568px') {
    width: 250px;
  }

  .ant-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-typography {
    text-align: center;
  }
}
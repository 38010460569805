.api-log {
  font-size: 13px;
  max-height: 70vh;
  overflow: auto;

  &__item {
    border-bottom: 1px solid $stroke;
    padding: 16px 0;
    &:nth-child(2n + 1) {
      border-right: 1px solid $stroke;
    }

    span {
      color: $greyMain;
    }
  }
}
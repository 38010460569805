.cell-partner-income {
  background: #fff;
  padding: 15px 0;
  height: 158px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  overflow: hidden;
  flex-wrap: wrap;
  .partner-income-details {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    list-style: none;
    padding-top: 5px;
    width: 100%;

    >* {
      width: 50%;
      text-align: center;
      font-size: 12px;
      +* {
        border-left: 1px solid rgba(0, 0, 0, 0.3);
      }
    }

    &__title {
      font-size: 10px;
      font-style: italic;
      color: rgba(0, 0, 0, 0.45);
    }
    &__value {
      font-weight: bold;
    }
  }

  &__total {
    text-align: center;
  }

  &__total-title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
  &__total-value {
    font-size: 20px;
    font-weight: bold;
  }
  p {
    margin: 0;
  }

  &--details {
    background: none;
    padding: 0;
    width: 175px;
    display: block;
    height: auto;



    .cell-partner-income {
      &__total-value {
        font-size: 12px;
      }
    }


    .partner-income-details {
      padding: 0;
      line-height: 13px;
      &__title {
        font-size: 9px;
      }
      &__value {
        font-size: 10px;
        color: rgba(0, 0, 0, 0.65);

      }
    }
  }
}
.dashboard-partner-income {
  .ant-card-body {
    padding: 0;
  }
}
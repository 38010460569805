.ant-switch {
  height: 10px;
  background: $switchBgColor;
  min-width: 30px;
  border-radius: 3px;
  &:after {
    top: -5px;
    margin-left: -4px;
    border-radius: 5px;
    background: $switchDisabledBtn;
  }

  &-handle {
    width: 10px;
    top: -5px;
    left: 0;
  }

  &-checked {
    .ant-switch-handle {
      width: 10px;
      top: -5px;
      left: calc(100% - 10px - 2px);
    }
    &:after {
      border-radius: 5px;
      content: url("data:image/svg+xml,%3Csvg width='13' height='9' viewBox='0 0 17 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.0647 1.36853C16.6895 1.99337 16.6895 3.00643 16.0647 3.63127L7.39804 12.2979C6.7732 12.9228 5.76014 12.9228 5.1353 12.2979L0.468629 7.63127C-0.15621 7.00644 -0.15621 5.99337 0.468629 5.36853C1.09347 4.74369 2.10653 4.74369 2.73137 5.36853L6.26667 8.90383L13.802 1.36853C14.4268 0.743693 15.4399 0.743693 16.0647 1.36853Z' fill='white'/%3E%3C/svg%3E%0A");
      z-index: 1;
      position: absolute;
      top: -5px;
      margin-left: 4px;
      background: $tertiaryActiveColor;
    }
  }
}

.error-page {
  @include flex($direction: column, $type: center);
  height: 100vh;
  width: 100%;
}
.error-page-content {
  text-align: center;


  &__code {
    font-weight: 700;
    font-size: 92px;
    line-height: 1;
  }
  &__title {
    font-size: 32px;
    font-weight: 700;
  }
}

$label: #cce0ff;
$label_selected: white;
$border: #cce0ff;
$border_selected: $secondaryMain;

.radio-select {
  &.ant-select-show-arrow {
    .ant-select-selector {
      padding-right: 30px;
    }
  }

  &-dropdown {
    background: none;
    box-shadow: none;

    & > div {
      background: $dart_blue;
      border-radius: 8px;
      margin-top: 10px;
      padding: 10px 5px;
    }

    &::before {
      @include arrow($dart_blue);
      position: absolute;
      top: 5px;
      left: calc(50% - 20px);
    }

    .ant-select-item-option {
      color: $label;

      .radio-select-option-inner {
        border-color: $border;
      }

      &-active,
      &-selected {
        background: transparent;
        color: $label_selected;
      }

      &-selected {
        .radio-select-option-inner {
          border-color: $border_selected;

          &::after {
            position: absolute;
            top: 3px;
            left: 3px;
            display: table;
            width: 10px;
            height: 10px;
            background-color: $secondaryMain;
            border-top: 0;
            border-left: 0;
            border-radius: 8px;
            content: " ";
          }
        }
      }
    }
  }
  &-option {
    &-wrapper {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;
      position: relative;
      display: inline-block;
      margin-right: 8px;
      white-space: nowrap;
      cursor: pointer;
    }

    &-inner {
      position: relative;
      top: 0;
      left: 0;
      display: block;
      width: 20px;
      height: 20px;
      border-color: $fieldsActiveBorderColor;
      border-style: solid;
      border-width: 2px;
      border-radius: 100px;
      transition: all 0.3s;
    }

    &-radio {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;
      font-feature-settings: "tnum";
      position: relative;
      top: 0;
      display: inline-block;
      line-height: 1;
      white-space: nowrap;
      vertical-align: sub;
      outline: none;
      cursor: pointer;
    }

    &-label {
      padding: 0 8px;
    }
  }
}

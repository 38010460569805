.notification {
  width: 100%;
  text-align: center;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  font-weight: bold;
  p {
    margin-bottom: 2px;
  }
}

.notification-success {
  background-color: $successStatus;
  font-weight: normal;
}
.notification-danger {
  background-color: $primaryMain;
}

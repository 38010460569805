.bonuses {
  &-row-expanded {
    & td:first-child {
      border-left: 2px solid $primaryMain;
    }

    & + tr td {
      border-left: 2px solid $primaryMain;
    }
  }
}

.ant{
  &-menu {
    &-item {
      &:hover {
        color: $primaryMain;
        border-color: $primaryMain !important;

        & > a {
          color: $primaryMain !important;

          .icon-custom,
          .icon-custom-secondary {
            path {
              fill: $primaryMain;
            }
          }
        }
      }

      &-selected {
        background-color: transparent !important;
        &:after {
          border-color: $primaryMain !important;
        }

        color: $primaryMain;
        border-color: $primaryMain !important;

        & > a {
          color: $primaryMain !important;

          & > i {
            color: $primaryMain !important;
          }
        }
      }
      & > a {
        & > i {
          color: $tertiaryActiveColor;
        }

        &:hover {
          color: $primaryMain;
          & > i {
            color: $primaryMain;
          }
        }
      }
    }
    &-submenu-title {
      span {
        padding-left: 24px;
      }
    }

    &-sub {
      .ant-menu-item {
        padding-left: 40px;
      }
    }
  }
}


@include tablet {
  .brands__bg-layer {
    padding-top: 52px;
  }

  .brands__wrap {
    .brands {
      &__item {
        max-width: 680px;

        &:not(:last-child){
          margin-bottom: 60px;
        }
      }
    }
  }
}

@include desktop {
  .brands__bg-layer {
    margin: 0 auto;
    padding-left: 158px;
    padding-right: 97px;
  }

  .brands__wrap {

    .header {
      &__desktop-menu {
        margin-right: 60px;
      }
    }

    .brands {
      margin-bottom: 195px;

      h2 {
        font-size: 56px;
        line-height: 65px;
        margin-bottom: 60px;
      }

      &__item {
        flex-direction: row;
        max-width: unset;

        &:not(:last-child) {
          margin-bottom: 104px;
        }

        &:nth-child(odd) {
          .mockup-cont {
            order: 2;
            margin-left: 63px;
          }
        }

        .mockup-cont {
          margin-bottom: 0;
          margin-right: 35px;
        }

        .btn__scroll {
          right: -20px;
          bottom: -45px;
        }
      }

      &__inner-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 0;
      }
    }

    .footer {
      padding-bottom: 50px;
    }
  }
}
.csv-settings {
  &__refresh {
    cursor: pointer;
    line-height: normal;
    &:hover {
      transform: scale(1.1);
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .anticon {
    padding: 8px;
    background: $primaryOpacity10;
    color: $primaryMain;
    border-radius: 100%;
    margin: -5px;
    &:hover {
      transform: scale(1.1);
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
.promo-archive {
  .archive-item {
    margin: 14px 0;
  }

  &__collapse {
    background-color: $white_bg;
    border: 1px solid #d9d9d9;
    border-radius: 15px;
    padding: 5px;
    & > .ant-collapse-item {
      border-bottom: none;
    }
  }

  &__modal{
    &__close {
      padding: 10px;
    }
  }
}
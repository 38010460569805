.project-info {
  overflow: hidden;
  text-overflow: ellipsis;

  &__img {
    height: 40px;
    display: block;
  }

  &__link {
    text-decoration: none;
    color: $tertiaryBtnColor;
    white-space: nowrap;
  }

  &__type {
    color: $greyMain;
  }
}

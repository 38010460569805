

.testimonials {
  align-content: center;

  .ant-card-head {
    border-radius: 10px 10px 0 0;
    color: #fff;
  }

  .testimonial-card {
    margin: 20px 0;


    &.ant-card {
      background: $darkBox;
      color: $white;

      .ant-card-body,.ant-card-head {
        padding: 10px;
      }
      .ant-card-head-title {
        padding: 0;
        font-size: 21px;
        font-weight: bold;
      }
      &.ant-card-bordered {
        border-color: $darkInput;
      }

      p {
        opacity: .5;
      }
      a {
        color: $primaryColor;
      }
    }
  }
}

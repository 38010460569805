.notification-list-item {
  margin-top: 20px;

  .global-notification {
    margin-bottom: 0;
  }

  &__edit {
    margin-left: auto;
    margin-right: 10px;
  }
  &__top {
    display: flex;
    align-items: center;


    .ant-switch {
      margin-right: 10px;
    }

  }
  &__time {
    display: flex;
    align-items: center;
    margin-left: 20px;
    font-size: 16px;
    margin-right: auto;

    .anticon {
      margin-right: 5px;
    }
    .ant-typography {
      margin: 0;
      margin-left: 10px;
    }
  }

  &--deleted {
    opacity: .5;
    transition: all .2s ease;


    .notification-list-item{
      &__time {
        margin-left: 0;
      }
    }

    .ant-btn {
      margin: 0 auto;
    }
    &:hover {
      opacity: 1;
    }
  }
}

.date-range {
  display: flex;
  align-items: center;

  .ant-form-item {
    margin: 0;
  }
  .anticon {
    flex: none;
    margin:  0 10px;
  }
  input {
    text-align: center;
  }
}
@mixin flex($type: default, $display: flex, $direction: null, $align-items: null, $justify-content: null) {
  display: $display;
  flex-direction: $direction;
  @if $type == center {
    justify-content: center;
    align-items: center;
  }
  @else if $type == between-center {
    justify-content: space-between;
    align-items: center;
  }
  @else if $type == start {
    justify-content: flex-start;
    align-items: flex-start;
  }
  align-items: $align-items;
  justify-content: $justify-content;
}
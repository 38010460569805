.ant-tabs {
  .ant-tabs-ink-bar {
    background: $primaryMain;
  }
  .ant-tabs-tab {
    padding: 24px 0;

    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $primaryMain;
    }
    &:hover {
      color: $primaryMain;
    }
  }
}

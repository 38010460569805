.wallet-log {
  .log-tag {
    white-space: unset;
    width: 100%;
    text-align: center;
  }

  .log-down-divider {
    text-align: center;
  }
}
.chat-form {
  display: flex;
  padding-right: 60px;
  position: relative;
 
  
  .ant-form-item  {
    flex: 1;
    margin: 0;

    textarea.ant-input {
      height: 90px;
      min-height: 90px;
      border-right: none;
      border-radius: 6px 0 0 6px;
    }
  }

  &__send {
    width: 60px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 6px 6px 0;
    display: block;
    border: none;
    background: none;
    background: $primaryMain;
    cursor: pointer;
    transition: background .3s ease;

    &:hover {
      background: $primaryActiveColor;
    }
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: url("../../../../../assets/images/inner-pages/chat/send.svg") no-repeat center / contain;
      width: 28px;
      height: 28px;
      animation: none !important;
      opacity: 1 !important;
      transition: transform .3s ease;
    }

    &:empty {
      visibility: visible;
      opacity: 1;
      width: 60px;
    }

    &:active{
      &::after {
        transform: translate(-50%, -50%) scale(1.15);
      }
    }
  }

  .ant-form-item-explain {
    position: absolute;
    left: 0;
    top: 105%;
    font-size: 12px;
  }

}
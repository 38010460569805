.footer {
  font-size: 14px;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    @include media('<1024px') {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }
  }

}

.footer-menu {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;

  @include media('<1024px') {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  li {
    +li {
      margin-left: 10px;
      @include media('<1024px') {
        margin-left: 0;
      }
    }
  }
  a {
    font-size: 12px;
    color: #fff;
    opacity: .75;
    transition: all .2s ease;
    &:hover {
      opacity: 1;
    }
    &.active {
      color: $primaryColor;
      opacity: 1;
    }
  }
}
.all-components {
  padding: 10px;

  &__item {
    text-align: center;
    border: 1px solid $alwaysWhite;
    &__title {
      padding: 15px 0;
      font-size: 16px;
      font-weight: 500;
    }
  }
}
.partner-balance {
  &__list {
    background: white;
    height: 100%;
    padding: 10px 10px;
    border-radius: 10px;
    @media (min-width: 768px) {
      padding: 24px 24px;
    }
  }
}
.ant-select {
  &.ant-select-show-arrow {
    .ant-select-selector {
      padding-right: 30px;
    }
  }

  &.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 45px;
  }
}

@import "sidebar";
@import "header";
@import "footer";
@import "return-admin";
@import "registration";

.main-container {
  padding: 20px;
}

.ant-layout {
  background: $main_bg;
}
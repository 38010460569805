.chat-new-form {


  textarea{

    &.ant-input {
      height: 200px;
    }
  }

  .ant-select-multiple{
    .ant-select-selection-placeholder {
      padding-left: 0;
    }
  }
}
.radio-btn {
  border: 1px solid;
  padding: 5px;
  display: inline-block;
  cursor: pointer;
  background: $alwaysWhite;
  color: $alwaysBlack;
  border: 1px solid $stroke;

  & + .radio-btn {
    border-left-color: transparent;
  }

  &:hover {
    border: 1px solid $alwaysGrey;
    box-shadow: $promoCardBoxShadow;
  }


  &__selected {
    border-color: $radioBtnActive !important;
    color: #1890ff;
  }



  &__number {
    margin-left: 10px;
    background: $stroke;
    border-radius: 5px;
    padding: 3px 5px;
  }
}
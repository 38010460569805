.about-us-section {
  position: relative;
  padding: 70px 0;

  @include media('<568px') {
    padding: 30px 0;
    margin: 0;
  }

  .btn {
    min-width: 210px;
    @include media("<568px") {
      width: 100%;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 44px;
    line-height: 1;
    @include media('<=860px') {
      font-size: 36px;
    }
    @include media("<568px") {
      font-size: 28px;
    }
  }
  &__content {
    position: relative;
    z-index: 2;
  }
  &__img {
    width: 577px;
    position: absolute;
    right: 100px;
    top: 0;

    @include media('<old') {
      width: 450px;
      right: -50px;
    }
    @include media('<=860px') {
      display: none;
    }
    img {
      max-width: 100%;
    }
  }
}
.about-list {
  list-style: none;
  color: #fff;
  font-size: 18px;
  line-height: 1.5;
  padding: 0;
  margin: 0;
  margin-top: 35px;
  margin-bottom: 50px;

  @include media("<568px") {
    font-size: 16px;
  }
  li {
    +li {
      margin-top: 5px;
    }
    position: relative;
    padding-left: 25px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 8px;
      width: 10px;
      height: 10px;
      background-color: $primaryColor;
      border-radius: 50%;
    }
  }
}